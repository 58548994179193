<style scoped></style>

<template>
  <Page />
</template>

<script>
import Page from './index.vue'
export default {
  components: { Page }
}
</script>
